<!--
 * @Author: your name
 * @Date: 2021-10-21 15:21:45
 * @LastEditTime: 2021-10-21 17:58:35
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \cloud_classroom_pc\src\views\course\course.vue
-->
<template>
  <div class="course">
    <div class="container">
      <!-- 课程分类 -->
      <div class="course-type">
        <div class="course-type-list">
          <div class="row-title">课程分类:</div>
          <span
            :class="courseType ? '' : 'active-course-type'"
            style="margin-left: 30px"
            @click="getCourseList('')"
            >全部</span
          >
          <div class="course-name-list">
            <span
              :class="[
                'course-name-item',
                courseType == item.id ? 'active-course-type' : '',
              ]"
              v-for="(item, index) in courseTypeList"
              :key="index"
              @click="getCourseList(item.id)"
              >{{ item.name }}</span
            >
          </div>
        </div>
      </div>
      <!-- 课程列表 -->
      <div class="row-name">课程列表</div>
      <div class="line"></div>
      <div class="course-list" v-if="courseList.length !== 0">
        <div
          class="course-item"
          @click="goDetail(item.id)"
          v-for="(item, index) in courseList"
          :key="index"
        >
          <div class="course-content">
            <div class="course-title">{{item.courseCategoryName}}</div>
            <div class="course-boby">
              <div class="course-img">
                <img
                  :src="item.logo2"
                  alt=""
                />
              </div>
              <div class="course-content-item">
                <div class="course-name">
                  {{ item.name }}
                </div>
                <div class="course-descr">
                  {{ item.descr }}
                </div>
              </div>
              <div class="el-btn"><el-button type="primary" round>进入课程</el-button></div>
            </div>
          </div>
        </div>
        <!-- 分页 -->
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @next-click="nextClick"
          @prev-click="prevClick"
          :hide-on-single-page="true"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
      <div v-else>
        <el-empty description="暂无数据"></el-empty>
      </div>
      <footer class="footer">
        <a target="_blank" href="https://beian.miit.gov.cn/">粤ICP备2021014802号</a>
      </footer>
    </div>
  </div>
</template>

<script>
import { getCourseType, getCourse } from "@/api/course";
export default {
  data() {
    return {
      courseTypeList: [],
      courseList: [],
      limt: 10,
      page: 1,
      total: 0,
      courseType: "",
    };
  },

  components: {},

  computed: {},

  mounted() {
    localStorage.setItem('coursePage','course')
    this.getCourseType();
    this.getCourseList("");
  },

  methods: {
    //获取课程类型
    getCourseType() {
      getCourseType().then((res) => {
        this.courseTypeList = res.data;
      });
    },
    //获取课程
    getCourseList(id) {
      this.courseType = id;
      //判断是否点击全部
      if (id) {
        getCourse({
          limit: this.limt,
          page: this.page,
          courseCategoryId: id,
        }).then((res) => {
          this.courseList = res.data.list;
          this.total = res.data.total;
        });
      } else {
        getCourse({ limit: this.limt, page: this.page }).then((res) => {
          this.courseList = res.data.list;
          this.total = res.data.total;
        });
      }
    },
    //点击下一页
    nextClick() {
      this.page = this.page + 1;
    },
    //点击上一页
    prevClick() {
      this.page = this.page - 1;
    },
    //改变页数
    currentChange(page) {
      this.page = page;
      this.getCourseList(this.courseType);
    },
    //进入课程详情
    goDetail(id) {
      this.$router.push({ path: "/courseDetail", query: { id: id } });
    },
  },
};
</script>
<style lang='scss' scoped>
span:hover {
  cursor: pointer;
}
.course {
  .course-type {
    padding: 25px;
    border: 1px solid #eeeeee;
    .active-course-type {
      color: #4394ff;
    }
    .course-type-list {
      border-bottom: 1px solid#F2F2F2;
      padding: 15px 0 15px 0;
      display: flex;
      .course-name-list {
        display: flex;
        flex: 1;
        margin-left: 30px;
        flex-wrap: wrap;
        .course-name-item {
          margin: 0 0 15px 30px;
        }
      }
    }
  }
  .el-pagination {
    text-align: center;
  }
  .row-name {
    font-size: 20px;
    margin-top: 30px;
    font-weight: 400;
  }
  .line {
    height: 5px;
    width: 60px;
    margin: 4px 0 30px 0;
    background-color: #4394ff;
    border-radius: 5px;
  }
  .course-item {
    border: 1px solid #eeeeee;
    padding: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.16);
    .course-type-name {
      margin-bottom: 15px;
    }
    .course-content {
      
      .course-boby{
        display: flex;
        position: relative;
      }
      .el-btn{
        position: absolute;
        bottom: 0;
        right: 0;
      }
      .course-title{
        margin-bottom: 15px;
      }
      .course-content-item {
        margin-left: 20px;
        .course-name {
          margin-bottom: 13px;
          font-size: 18px;
        }
        .course-descr {
          color: #b1b1b1;
          line-height: 20px;
        }
      }
    }
  }
}
.course-img{ 
   width:224px; 
   height:160px;
   overflow:hidden
} 
.course-img img{
  max-width:300px;
  height:160px;
  width: 224px;
} 
</style>